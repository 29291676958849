import axios from "axios";

export function request(config) {
  const instance = axios.create({
    baseURL: "https://cloud.aifhold.com",
    timeout: 60000,
  });
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (res) => {
      if (res.data.code === 200) {
        return res.data;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance(config);
}
