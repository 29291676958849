import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes";
import Antd from "ant-design-vue";
import { createPinia } from "pinia";
import "./assets/css/main.css";
import "./assets/css/reset.css";
import "ant-design-vue/dist/antd.css";
import CHeader from "@/components/CHead.vue";
import CFooter from "@/components/CFooter.vue";

// 5. 创建并挂载根实例
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
//确保 _use_ 路由实例使
//整个应用支持路由。
app.use(Antd);
app.use(router);
app.component("CHeader", CHeader);
app.component("CFooter", CFooter);
app.mount("#app");
