import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../pages/home";
import Detail from "../pages/detail";
import MenuDetail from "../pages/menuDetail";
// import Index from "../pages/index";
const routes = [
  // { path: "/", component: Index },
  { path: "/", component: Home },
  { path: "/detail", component: Detail },
  { path: "/menuDetail", component: MenuDetail },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
