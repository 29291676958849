<template>
  <CHeader />
  <div class="menuDetail">

    <div class="content">
      <div class="banner">
        <img :src="activeMenu?.columnImg || ''" alt="" />
      </div>
      <div class="head">
        <div class="title">{{ activeMenu?.columnName || "" }}</div>
        <div class="bread-crumb">
          <HomeOutlined style="color: #be5833; margin-right: 10px" />
          <div class="">当前位置：</div>
          <div class="crumb-item" v-for="(item, index) in breadCrumbList" :key="index" @click="jump(item)">
            <div :class="{
          'item-text-before': index !== 0,
          'item-text': true,
        }">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu">
        <div v-for="item in leftMenuList" :key="item.columnId" class="menu-item"
          :class="{ 'item-active': route.query.childId === item.columnId }" @click="changeMenu(item)">
          {{ item?.columnName || "" }}
        </div>
      </div>
      <div class="value">
        <template v-if="avtiveChildMenu?.contentType === '1'">
          <div class="info-content">
            <div class="info-content-value" v-html="list?.[0]?.articleContent || ''"></div>
          </div>
        </template>
        <template v-if="avtiveChildMenu?.contentType === '2'">
          <div class="list">
            <div class="list-item" v-for="item in list" :key="item.articleId">
              <div class="item-logo" @click="jumpDetail(item)">
                <img :src="item.articleImg" />
              </div>

              <div class="item-info">
                <div class="title" @click="jumpDetail(item)">
                  {{ item?.articleTitle || "" }}
                </div>
                <div class="time">{{ item?.fbTime || "" }}</div>
                <div class="info" v-html="item?.articleContent || ''"></div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <a-pagination v-model:current="pageInnfo.page" :total="total" show-less-items @change="_getList" />
          </div>
        </template>
      </div>
    </div>
  </div>
  <CFooter />
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { HomeOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { useMenuStore } from "@/store";
import { findArticles } from "@/network";
const store = useMenuStore();
const router = useRouter();
const route = useRoute();
const childId = computed(() => route.query.childId);
const menuList = computed(() => store.menuList);

const activeMenu = computed(() => {
  return menuList.value.find((item) => item.columnId === route.query.id);
});

const leftMenuList = computed(() => {
  return (
    menuList.value.find((item) => item.columnId === route.query.id)?.children ||
    []
  );
});

const avtiveChildMenu = computed(() => {
  return leftMenuList.value.find(
    (item) => item.columnId === route.query.childId
  );
});
const breadCrumbList = computed(() => {
  return [
    {
      title: "首页",
    },
    {
      title: activeMenu?.value?.columnName || "",
    },
    {
      title: leftMenuList.value.find(
        (item) => item.columnId === route.query.childId
      )?.columnName,
    },
  ];
});
const jump = (item) => {
  if (item.title === "首页") {
    router.push("/");
  }
};

const list = ref([]);
const pageInnfo = ref({
  page: 1,
  limit: 10,
});
const total = ref(0);
const _getList = async () => {
  const res = await findArticles({
    ...pageInnfo.value,
    columnId: childId.value,
    isD: "isD",
  });
  list.value = res?.data || [];
  total.value = res?.count || 0;
};
const changeMenu = async (val) => {
  router.push({
    path: "/menuDetail",
    query: {
      id: route.query.id,
      childId: val.columnId,
    },
  });
};
const jumpDetail = (val) => {
  if (val.articleOutLink) {
    window.open(val.articleOutLink);
  } else {

    router.push({
      path: "/detail",
      query: {
        id: route.query.id,
        childId: route.query.childId,
        articleId: val.articleId,
      },
    });
  }
};
watchEffect(() => {
  _getList();
});
console.log(jumpDetail, changeMenu, avtiveChildMenu);
</script>
<style lang="scss" scoped>
.menuDetail {
  margin-bottom: 20px;



  .content {
    .banner {


      img {
        width: 100%;
        height: 300px;
      }
    }

    width: 1200px;
    margin: 0 auto;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;

      .title {
        font-size: 24px;
        color: #cc0000;
        font-weight: bold;
      }

      .bread-crumb {
        display: flex;
        align-items: center;
        padding-right: 100px;

        .crumb-item {
          cursor: pointer;

          .item-text {
            color: #666;
            display: flex;
            align-items: center;

            &:hover {
              color: #cc0000;
            }
          }

          .item-text-before::before {
            content: ">>";
            padding: 0 5px;
            color: #666 !important;
          }
        }
      }
    }

    .menu {
      display: flex;

      font-size: 15px;
      background-color: rgb(246, 246, 246);
      border-color: rgb(204, 204, 204);
      border-width: 1px;
      border-style: solid;

      .menu-item {
        height: 50px;
        line-height: 50px;
        text-align: center;
        width: 144px;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          font-weight: bold;
          background-color: #cc0000;
        }
      }

      .item-active {
        color: #ffffff;
        font-weight: bold;
        background-color: #cc0000;
      }
    }

    .value {
      margin-top: 20px;
      padding: 20px;
      border-color: rgb(204, 204, 204);
      border-width: 1px;
      border-style: solid;

      .list {
        .list-item {
          display: flex;
          padding-bottom: 15px;
          border-bottom: 1px dotted #dddddd;
          margin-bottom: 15px;

          .item-logo {
            margin-right: 15px;
            flex-shrink: 0;
            width: 200px;
            height: 100px;

            overflow: hidden;

            img {
              width: 200px;
              height: 100px;
              transition: all 0.3s;
            }

            &:hover {
              img {
                transform: scale(1.2);
              }
            }
          }

          .title {
            font-size: 16px;
            color: #5a5a5a;
            cursor: pointer;

            &:hover {
              color: #481e1e;
            }
          }

          .time {
            font-size: 14px;
            padding-top: 4px;
            color: #929292;
          }

          .info {
            font-size: 13px;
            color: #5a5a5a;
            height: 50px;
            overflow: hidden;
            height: 44px;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
  }

  .ant-menu-item {
    width: 90px;
    height: 26px !important;
    line-height: 26px !important;
    margin: 0 !important;
    padding: 0 8px !important;
  }

  // 设置菜单项鼠标滑过样式
  .ant-menu-item-active {
    background-color: #c60b09 !important;
    color: #fff !important;
  }

  // 设置子菜单样式
  .ant-menu-submenu>.ant-menu {
    width: 100px;
    min-width: 100px;
  }
}
</style>
