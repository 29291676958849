<template>
  <div class="publicWrapper">
    <PublicHead :more="handleMore" :title="props.title" />
    <div class="content">
      <div class="banner" v-if="!props.noBanner">
        <img :src="activeMenu?.columnImg" />
      </div>
      <div class="list">
        <div class="menu">
          <div @click="handleChildMenu(item)" v-for="item in activeMenu?.children || []" :key="item.columnId"
            class="menu-item" :class="{
              'menu-item-active': activeChildMenu.columnId === item.columnId,
            }">
            {{ item.columnName }}
          </div>
        </div>
        <div class="list-item" v-for="i in list" :key="i.articleId">
          <!-- <div @click="handleMore" class="list-item-left">
            [{{ activeChildMenu.columnName }}]
          </div> -->
          <div @click="goToDetail(i)" class="list-item-center ellipsis-1">
            {{ i.articleTitle }}
          </div>
          <div class="list-item-right">{{ i.fbTime.substr(0, 10) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watch } from "vue";
import PublicHead from "./PublicHead.vue";
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
// import { HomeOutlined } from "@ant-design/icons-vue";
// import { useRoute } from "vue-router";
// import { useMenuStore } from "@/store";
import { findArticles } from "@/network";
import { useMenuStore } from "@/store";
const props = defineProps({
  noBanner: Boolean,
  title: String,
});

const store = useMenuStore();
const router = useRouter();
const menuList = computed(() => store.menuList || []);
const activeMenu = computed(() => {
  return menuList.value.find((item) => item.columnName === props.title);
});
const activeChildMenu = ref({});
watch(activeMenu, (val) => {
  console.log("val", val);
  if (val?.children?.length) {
    activeChildMenu.value = val?.children?.[0] || {};
  }
});

const list = ref([]);
const pageInnfo = ref({
  page: 1,
  limit: 6,
});

const _getList = async () => {
  const res = await findArticles({
    ...pageInnfo.value,
    columnId: activeChildMenu.value.columnId,
    isD: "isD",
  });
  list.value = res?.data || [];
};

const handleChildMenu = (item) => {
  activeChildMenu.value = item;
};

const handleMore = () => {
  router.push({
    path: "/menuDetail",
    query: {
      id:
        activeChildMenu.value.columnGrade === "1"
          ? activeChildMenu.value.columnId
          : activeChildMenu.value.upColumnId,
      childId:
        activeChildMenu.value.columnGrade === "1"
          ? activeChildMenu.value?.children?.[0].columnId
          : activeChildMenu.value.columnId,
    },
  });
};
const goToDetail = (item) => {

  if (item.articleOutLink) {
    window.open(item.articleOutLink);
  } else {

    router.push({
      path: "/detail",
      query: {
        id:
          activeChildMenu.value.columnGrade === "1"
            ? activeChildMenu.value.columnId
            : activeChildMenu.value.upColumnId,
        childId:
          activeChildMenu.value.columnGrade === "1"
            ? activeChildMenu.value?.children?.[0].columnId
            : activeChildMenu.value.columnId,
        articleId: item.articleId,
      },
    });
  }
};
watchEffect(() => {
  if (activeChildMenu.value?.columnId) {
    _getList();
  }
});
</script>
<style lang="scss" scoped>
.publicWrapper {
  // padding: ;
  overflow: hidden;
  width: 100%;


  .content {
    min-height: 100px;
    margin-top: 15px;
    display: flex;

    .banner {
      height: 270px;
      width: 360px;
      margin-right: 15px;

      img {
        height: 270px;
        width: 360px;
      }
    }

    .list {
      overflow: hidden;
      width: -webkit-fill-available;

      .menu {
        display: flex;
        background-color: #f0f0f0;
        overflow: auto;

        .menu-item {
          flex-shrink: 0;
          text-align: center;
          width: 120px;
          height: 40px;
          line-height: 40px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 14px;
          color: #000;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: #cc0000;
          }
        }

        .menu-item-active {
          color: #fff;
          background-color: #cc0000;
        }
      }

      .list-item {
        padding: 8px 0 8px 10px;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;
        display: flex;

        .list-item-left {
          color: #666666;
          margin-right: 5px;
          cursor: pointer;

          &:hover {
            color: #2c90ff;
          }
        }

        .list-item-center {
          flex: 1;
          cursor: pointer;

          &:hover {
            color: #cc0000;
          }
        }

        .list-item-right {
          color: #acacac;
        }
      }
    }
  }
}
</style>