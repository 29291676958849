<template>
  <div class="yz-footer">
    <div class="info">
      <div class="yz-logo">
        <img src="@/assets/logo.jpg" />
      </div>
      <div
        style="
          width: 300px;
          margin: 0 auto;
          padding: 20px 0;
          text-align: center;
        "
      >
        <!-- <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35058202000588"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img src="@/assets/gongan.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            闽公网安备 35058202000588号
          </p></a
        > -->
      </div>
      <el-row
        class="yz-info"
        style="display: block; text-align: center"
      >
        <span
          @click="
            toLink(
              'https://external.h3yun.com/su/re/kNUxA?engineCode=punujncjdmru0w81uygl0ln66'
            )
          "
          >学分认定查询</span
        >
        <i>|</i>
        <span @click="toLink('https://gaokao.chsi.com.cn/')">阳光高考网</span>
        <i>|</i>

        <span @click="toLink('https://jyt.fujian.gov.cn/')">福建省教育厅</span>
        <i>|</i>
        <span @click="toLink('https://www.eeafj.cn/')">福建省教育考试院</span>
      </el-row>
      <br />
      <br />
      <el-row class="yz-info">
        <span>主办单位：泉州科技中学</span>
        <i>|</i>

        <span>地址：福建省泉州市鲤城区学府路132号</span>
        <i>|</i>
        <span>服务电话：</span>
        <i>|</i>
        <span>邮箱：</span>
        <i>|</i>
        <span
          ><a
            target="blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            >互联网备案记录 闽ICP备14008985号-1</a
          ></span
        >
      </el-row>
    </div>
  </div>
</template>
<script setup>
const toLink = (url) => {
  window.open(url);
};
</script>
<style lang="scss" scoped>
.yz-footer {
  background-color: #383838;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  .info {
    .yz-logo {
      text-align: center;
      margin-bottom: 30px;
      img {
        width: 244px;
        height: 70px;
      }
    }
    .yz-info {
      color: #888;
      span {
        cursor: pointer;
        margin: 0 20px;
        &:hover {
          color: #fff;
        }
      }
      a {
        color: #888;
      }
      i {
        font-style: normal;
      }
    }
  }
}
@media only screen and (max-width: 780px) {
  .yz-footer {
    padding: 10px;
    .info {
      .yz-logo {
        text-align: center;
        margin-bottom: 30px;
        img {
          width: 184px;
          height: 50px;
        }
      }
    }
  }
  .yz-info {
    display: flex;
    flex-direction: column;
    span {
      padding: 5px 0;
    }
    i {
      display: none;
    }
  }
}
</style>
