import { request } from './request'
// 查询栏目列表
export function getWebsiteColumnList() {
  return request({
    url: '/api-website/website/column/anon/getWebsiteColumnList',
    method: 'post',
    params: {
      columnType: 'NAVIGATION',
    },
  })
}
// 查询内容
export function findArticles(params) {
  return request({
    url: '/api-website/website/article/anon/findArticles',
    method: 'post',
    params,
  })
}
// 获取轮播列表
export function findResources(params) {
  return request({
    url: '/api-website/website/resources/anon/findResources',
    method: 'post',
    params,
  })
}

// 更新阅读人数
export function updateArticlesSumYd(articleId) {
  return request({
    url: '/api-website/website/article/anon/updateArticlesSumYd',
    method: 'post',
    params: {
      articleId,
    },
  })
}
