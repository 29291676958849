<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <CHeader />
  <div class="home">
    <div class="banner">
      <a-carousel class="banner-box" autoplay>
        <img :title="item.remark" v-for="item in bannerList" :key="item.id" :src="item.resourcesTpdz" />
      </a-carousel>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="flex1">
          <PublicWrapper title="科中新闻" />
        </div>
        <div class="wauto">
          <PublicWrapper title="明真德育" noBanner />
        </div>

      </div>
      <div class="wrapper">
        <div class="flex1">

          <PublicWrapper title="媒体聚焦" noBanner />


        </div>
        <div class="wauto" style="margin-left: 20px">
          <PublicWrapper title="党建之窗" noBanner />
        </div>

      </div>
      <div class="wrapper">

        <div class="flex1">
          <!-- <PublicHead title="教学教研" noMore />
          <ImageBox title="教学教研" /> -->
          <PublicWrapper title="教学教研" noBanner />
        </div>
        <div class="wauto">
          <PublicWrapper title="招生招聘" noBanner />
        </div>
      </div>
      <!-- <div class="wrapper">

        <div class="wauto">

        </div>
      </div> -->
      <div class="wrapper" v-if="false">
        <div class="flex1">
          <PublicHead title="校园风光" noMore />
          <!-- <div class="">空</div> -->
        </div>
        <div class="wauto">
          <!-- <PublicHead title="关于我们" noMore />
          <div class="code">
            <img src="@/assets/code.png" />
            <span>关注学校公众号，获取更多咨讯</span>
          </div> -->
          <PublicHead title="快速导航" noMore />
          <MenuBox />
        </div>
        <div class="wauto">
          <PublicHead title="关于我们" noMore />
          <div class="code">
            <img src="@/assets/code.png" />
            <span>关注学校公众号，获取更多咨讯</span>
          </div>
          <!-- <PublicHead title="快速导航" noMore />
          <MenuBox /> -->
        </div>
        <!-- <div class="w260">
          <PublicHead noMore />
          <MenuBox />
        </div> -->
      </div>
    </div>
  </div>
  <CFooter />
</template>
<script setup>
import { ref } from "vue";
import { findResources } from "@/network/index";
import PublicWrapper from "./components/PublicWrapper.vue";
import PublicHead from "./components/PublicHead.vue";
import MenuBox from "./components/MenuBox.vue";
// import ImageBox from "./components/ImageBox.vue";
const bannerList = ref([]);
const _getBanner = async () => {
  const res = await findResources({ resType: "LBT" });
  bannerList.value = res?.data || [];
  console.log(res);
};
_getBanner();
</script>
<style lang="scss" scoped>
.home {
  width: 1200px;
  margin: auto;

  .banner {
    .banner-box {
      img {
        width: 100%;
        // height: calc(100vh - 120px);
        height: 300px;
      }
    }
  }
}

.content {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.wrapper {
  border: 1px solid rgb(221, 221, 221);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
  }
}

.flex1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
}

.w260 {
  width: 260px;
  flex-shrink: 0;
  margin-left: 20px;
}

.wauto {
  width: 360px;
  flex-shrink: 0;
  margin-left: 20px;
}
</style>