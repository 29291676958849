<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <CHeader />
  <div class="detail">

    <div class="content">
      <div class="bg">
        <img :src="activeMenu?.columnImg" />
      </div>
      <div class="head">
        <div class="title">校园动态</div>
        <div class="bread-crumb">
          <HomeOutlined style="color: #be5833; margin-right: 10px" />
          <div class="">当前位置：</div>
          <div class="crumb-item" v-for="(item, index) in breadCrumbList" :key="index" @click="jump(item)">
            <div :class="{
          'item-text-before': index !== 0,
          'item-text': true,
        }">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="value">
        <div class="value-head">
          <div class="title">{{ detail?.articleTitle }}</div>
          <div class="other">
            <div style="margin-right: 20px">
              作者：{{ detail?.articleAuthor }}
            </div>
            <div style="margin-right: 20px">
              发布时间：{{ detail?.fbTime || "" }}
            </div>
            <div>{{ detail?.sumYd || 0 }}次浏览</div>
          </div>
        </div>
        <div v-html="detail?.articleContent || ''"></div>
      </div>
      <div class="xg-detail">
        <span>相关新闻：</span>
        <div class="xg-box">
          <div class="xg-item" @click="jumpDetail(item)" v-for="item in xgList" :key="item.articleId">
            {{ item?.articleTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <CFooter />
</template>
<script setup>
import { HomeOutlined } from "@ant-design/icons-vue";
import { ref, computed, watchEffect } from "vue";
import { updateArticlesSumYd, findArticles } from "@/network/index";
import { useRouter, useRoute } from "vue-router";
import { useMenuStore } from "@/store";
const route = useRoute();
const store = useMenuStore();
const articleId = computed(() => {
  return route?.query?.articleId || "";
});
const columnId = computed(() => {
  return route?.query?.childId || "";
});
const menuList = computed(() => store.menuList);

const activeMenu = computed(() => {
  return menuList.value.find((item) => item.columnId === route.query.id);
});

const leftMenuList = computed(() => {
  return (
    menuList.value.find((item) => item.columnId === route.query.id)?.children ||
    []
  );
});

const avtiveChildMenu = computed(() => {
  return leftMenuList.value.find(
    (item) => item.columnId === route.query.childId
  );
});

const breadCrumbList = computed(() => {
  return [
    {
      title: "首页",
    },
    {
      ...activeMenu.value,
      title: activeMenu.value?.columnName,
    },
    {
      ...avtiveChildMenu.value,
      title: avtiveChildMenu.value?.columnName,
    },
  ];
});

const detail = ref({});
const xgList = ref([]);
const router = useRouter();

const _getDetail = async () => {
  try {
    const res = await findArticles({
      columnId: columnId.value,
      articleId: articleId.value,
      page: 1,
      limit: 10,
    });
    detail.value = res?.data?.[0];
  } catch {
    console.log("error");
  }
};
const getXgList = async () => {
  try {
    const res = await findArticles({
      page: 1,
      limit: 5,
      columnId: columnId.value,
      zr: "zr",
    });

    xgList.value = res?.data || [];
  } catch {
    console.log("error");
  }
};

const _updateArticlesSumYd = async () => {
  try {
    await updateArticlesSumYd(articleId.value);
  } catch {
    console.log("error");
  }
};

const jump = (item) => {
  if (item.title === "首页") {
    return router.push("/");
  } else {
    router.push({
      path: "/menuDetail",
      query: {
        id: item.columnGrade === "1" ? item.columnId : item.upColumnId,
        childId:
          item.columnGrade === "1"
            ? item?.children?.[0].columnId
            : item.columnId,
      },
    });
  }
};

const jumpDetail = async (val) => {
  // alert(val)
  if (val.articleOutLink) {
    window.open(val.articleOutLink);
  } else {

    router.push({
      path: "/detail",
      query: {
        id: route.query.id,
        childId: route.query.childId,
        articleId: val.articleId,
      },
    });
  }
};
watchEffect(() => {
  _getDetail();
  getXgList();
  _updateArticlesSumYd();
});
</script>
<style lang="scss" scoped>
.detail {
  margin-bottom: 20px;



  .content {
    .bg {
      width: 100%;

      img {
        width: 100%;
        height: 300px;
      }
    }

    width: 1200px;
    margin: 0 auto;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .title {
      font-size: 24px;
      color: #cc0000;
      font-weight: bold;
    }

    .bread-crumb {
      display: flex;
      align-items: center;
      padding-right: 100px;

      .crumb-item {
        cursor: pointer;

        .item-text {
          color: #666;
          display: flex;
          align-items: center;

          &:hover {
            color: #cc0000;
          }
        }

        .item-text-before::before {
          content: ">>";
          padding: 0 5px;
          color: #666 !important;
        }
      }
    }
  }

  .value {
    margin-top: 20px;
    padding: 20px;
    border-color: rgb(204, 204, 204);
    border-width: 1px;
    border-style: solid;

    .value-head {
      margin-top: 20px;
      text-align: center;

      .title {
        font-size: 20px;
        color: #434343;
        font-weight: bold;
      }

      .other {
        margin-bottom: 15px;
        margin-top: 15px;
        font-size: 12px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }

  .xg-detail {
    display: flex;
    padding: 20px 0;

    span {
      flex-shrink: 0;
    }

    .xg-box {
      .xg-item {
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
          color: #be5833;
        }
      }
    }
  }
}
</style>
