import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getWebsiteColumnList } from '@/network/index';

export const useMenuStore = defineStore('menu', () => {
  const menuList = ref([]);
  const getMenuList = async () => {
    const res = await getWebsiteColumnList();

    const modifiedMenuList = res?.data
      .map((item) => {
        if (item.isShow === '否') {
          return null; // 返回 null 表示删除该项
        } else if (
          item.children &&
          item.children.length > 0 &&
          item.children[0].isShow === '否'
        ) {
          const { children, ...newItem } = item;
          console.log(children, '删除的属性');
          return newItem;
        } else {
          return item;
        }
      })
      .filter((item) => item !== null);
    menuList.value = [
      {
        columnName: '首页',
      },
      ...(modifiedMenuList || []),
    ];
  };

  return { menuList, getMenuList };
});
