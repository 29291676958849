<template>
  <div class="publicHead">
    <span class="title"> {{ props.title }} </span>
    <span @click="handleMore" class="more" v-if="!props.noMore">MORE</span>
  </div>
</template>
<script setup>
import { defineProps } from "vue";

const props = defineProps({
  noMore: Boolean,
  title: String,
  more: Function,
});

const handleMore = () => {
  props?.more();
};
</script>
<style lang="scss" scoped>
.publicHead {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(221, 221, 221);
  width: 100%;
  .title {
    font-size: 18px;
    font-family: 微软雅黑;
    color: rgb(204, 0, 0);
    font-weight: bold;
  }
  .more {
    color: rgba(102, 102, 102, 1);
    font-family: Microsoft YaHei;
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
  }
}
</style>
