<template>
  <div class="head">
    <div class="box"></div>
    <div class="head-wrapper">
      <div class="logo">
        <img src="@/assets/logo.jpg" />
      </div>
      <!-- <div class="menu">
        <div @mouseover.stop="handleMenu(item)" @mouseout="handleMenuOut(item)" class="menu-item"
          :class="{ 'active-item': item.columnId === idObj.id }" v-for="(item, index) in menuList" :key="index">
          <span>{{ item.columnName }}</span>

          <div class="sub-menu" v-if="item.checked">
            <div v-for="(child, cIndex) in item?.children || []" :key="cIndex" class="sub-menu-item"
              @click.stop="handleMenu2(child)">
              {{ child.columnName }}
            </div>
          </div>
        </div>
      </div> -->


      <div class="menu">
        <a-menu v-model:selectedKeys="current" mode="horizontal" :selectable="false">
          <a-sub-menu @click="handleMenu(item)" v-for="(item, index) in menuList" :key="index"
            :content="item.columnName">
            <template #title>{{ item.columnName }}</template>
            <a-menu-item-group v-if="item.children">
              <a-menu-item v-for="(child, cIndex) in item?.children || [{ columnName: '暂无数据' }]"
                :key="'setting:' + cIndex" @click="handleMenu2(child)">{{
          child.columnName }}</a-menu-item>
            </a-menu-item-group>
          </a-sub-menu>
        </a-menu>

        <!-- <a-popover @mouseover.stop="handleMenu(item)" @mouseout="handleMenuOut(item)" v-for="(item, index) in menuList"
          :key="index" trigger="hover" :content="item.columnName">
          <a-button :class="{ 'active-item': item.columnId === idObj.id }" type="primary"
            v-for="(child, cIndex) in item?.children || []" :key="cIndex" class="sub-menu-item"
            @click.stop="handleMenu2(child)">{{ child.columnName }}</a-button>
        </a-popover> -->

        <!-- <div  class="menu-item"
          :class="{ 'active-item': item.columnId === idObj.id }" v-for="(item, index) in menuList" :key="index">
          <span>{{ item.columnName }}</span>

          <div class="sub-menu" v-if="item.checked">
            <div v-for="(child, cIndex) in item?.children || []" :key="cIndex" class="sub-menu-item"
              @click.stop="handleMenu2(child)">
              {{ child.columnName }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useMenuStore } from "@/store";
const store = useMenuStore();
store.getMenuList();
const menuList = computed(() => store.menuList);
const router = useRouter();
// const route = useRoute();

// const leftMenuList = computed(() => {
//   return (
//     menuList.value.find((item) => item.columnId === route.query.id)?.children ||
//     []
//   );
// });
const handleMenu = async (val) => {
  if (val.columnName === "首页") {
    return router.push("/");
  }
  router.push({
    path: "/menuDetail",
    query: {
      id: val.columnGrade === "1" ? val.columnId : val.upColumnId,
      childId:
        val.columnGrade === "1" ? val?.children?.[0].columnId : val.columnId,
    },
  });

};
// const handleMenuOut = async (val) => {
//   if (val.columnName === "首页") {
//     return router.push("/");
//   }
//   val.checked = false

// };
const handleMenu2 = async (val) => {
  if (val.columnName === "首页") {
    return router.push("/");
  }
  if (val.columnName === "暂无数据") {
    return false;
  }
  router.push({
    path: "/menuDetail",
    query: {
      id: val.columnGrade === "1" ? val.columnId : val.upColumnId,
      childId:
        val.columnGrade === "1" ? val?.children?.[0].columnId : val.columnId,
    },
  });
}
// const idObj = computed(() => route.query);
</script>
<style lang="scss" scoped>
.head {
  .box {
    height: 20px;
    background-color: rgb(204, 0, 0);
  }

  .head-wrapper {
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;

    .logo {
      width: 297px;
      height: 66px;
      margin-right: 10px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .menu {
      flex: 1;
      display: flex;

      .menu-item {
        flex: 1;
        cursor: pointer;
        text-align: center;

        &:hover {
          color: #cc0000;
        }
      }

      .active-item {
        color: #cc0000;
      }
    }

    .sub-menu {
      background: red;
      text-align: center;
      width: 100%;
      height: 45px;
      line-height: 45px;
      overflow: hidden;
    }

    .sub-menu:hover {
      background: black;
      cursor: pointer;
    }

  }
}
</style>
